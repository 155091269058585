.blocker._custom {
  z-index: 999;
  padding: 10px;
}

.modal {

  &._custom {
    padding: 40px 15px 30px;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    @media(min-width: $screen-md) {
      padding: 50px;
    }
  }

  &._custom &__close {
    background: none;
    text-indent: 0;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
    padding: 10px;
  }

  &__close-icon {
    display: inline-block;
    color: #d9d6ca;
    //@include sprite-svg(close_gray);
    //font-size: mq-px2em(20px, sprite-attr(close_gray, width));
  }

  &__title {
    text-transform: uppercase;
    font-size: 16px;
    color: $BFont-color;
    margin-bottom: 20px;
    @media(min-width: $screen-md) {
      margin-bottom: 50px;
      font-size: 20px;
    }
  }


  &__submit-wr {
    margin-top: 20px;
    text-align: center;
    @media(min-width: $screen-md) {
      margin-top: 50px;
    }
  }
}

.modal-form {
  &__group + &__group {
    margin-top: 12px;
  }
}
