.navbar-toggle {
  padding: 10px 5px;

  @media(min-width: $screen-sm) {
    padding: 10px;
  }

  &__inner {
    width: 28px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__sep {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background-color: #000000;
  }

  &__sep + &__sep {
    margin-top: 5px;
  }
}
