@mixin gutters($count) {
  margin-left: -$count/2;
  margin-right: -$count/2;
  & > * {
    padding-left: $count/2;
    padding-right: $count/2;
  }
}

@mixin gutters-responsive($counts) {

  @each $name, $breakpoint in $grid-breakpoints {

    @each $count in $counts {
      @if ($name == 'xs') {

        .gutters-#{$count} {
          @include gutters($count);
        }
      }
    }

    @each $count in $counts {

      @media (min-width: #{$breakpoint}) {
        .gutters-#{$count}-#{$name} {
          @include gutters($count);
        }
      }
    }

  }
}
