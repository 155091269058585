@mixin flex-row($gutters: $grid-gutter-width, $wrap: wrap, $justify: space-between, $align: stretch, $direction: row) {
  display: flex;
  flex-wrap: $wrap;
  align-items: $align;
  justify-content: $justify;
  margin-left: -$gutters/2;
  margin-right: -$gutters/2;
  flex-direction: $direction;

  & > * {
    padding-left: $gutters/2;
    padding-right: $gutters/2;
  }
}

@mixin flex-row-responsive () {
  @each $name, $breakpoint in $grid-breakpoints {

    @media (min-width: #{$breakpoint}) {
      .flex-row-#{$name} {
        @include flex-row;
      }
    }
  }
}
