.form-checkbox {
  position: relative;
  display: block;
  cursor: pointer;
  padding-left: 25px;
  &__input {
    font-size: 0;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__field {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 2px solid #d9d6ca;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__input:checked ~ &__field {
    border: 5px solid #33712f;
  }

  &__title {
    display: block;
    color: #3a3a3a;
    font-size: 14px;
  }

  &__desc {
    display: block;
    color: #939393;
    margin-top: 5px;
    font-size: 14px;
  }
}