@mixin vertical() {

  @each $name, $breakpoint in $grid-breakpoints {

    @if($name == 'xs') {
      .f-top {
        align-items: flex-start!important;
      }

      .top {
        vertical-align: top!important;
      }

      .f-bottom {
        align-items: flex-end!important;
      }

      .bottom {
        vertical-align: bottom!important;
      }

      .f-middle {
        align-items: center!important;
      }

      .middle {
        vertical-align: middle!important;
      }
    }

    @media (min-width: #{$breakpoint}) {
      .f-top-#{$name} {
        align-items: flex-start;
      }

      .top-#{$name}  {
        vertical-align: top!important;
      }

      .f-bottom-#{$name} {
        align-items: flex-end;
      }

      .bottom-#{$name} {
        vertical-align: bottom!important;
      }

      .f-middle-#{$name} {
        align-items: center!important;
      }

      .middle-#{$name} {
        vertical-align: middle!important;
      }
    }
  }
}
