.navbar-nav {

  margin-left: -10px;
  margin-right: -10px;

  @media(min-width: $screen-sm) {
    @include flex-row;
    @include gutters(10px);
  }

  &__link {
    padding: 10px;
    display: block;
    text-transform: uppercase;
  }

  &__submenu {
    display: none;
  }
}
