.navbar {

  padding: 10px 0;

  /* ---------------------------------Inner------------------------------------ */
  
  &__inner {
    @include flex-row;
    @include gutters(10px);
    align-items: center;
  }

  &__inner-wr {
    position: relative;
  }

  &__inner > * {
    @media(max-width: $screen-sm - 1) {
      flex: 1 1 0;
    }
  }

  /* ---------------------------------Logo------------------------------------ */
  
  &__logo {
    width: 200px;
    @media(max-width: $screen-sm - 1) {
      width: 150px;
      flex: 0 1 auto;
    }
  }

  /* ---------------------------------Menu------------------------------------ */
  
  &__menu-wr {
    @media(max-width: $screen-sm - 1) {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    @media(min-width: $screen-sm) {
      display: block;
      height: auto!important;
    }
  }
  
  &__menu-content {
    @media(max-width: $screen-sm - 1) {
      margin-top: 20px;
    }

    @media(min-width: $screen-sm) {
      display: none;
    }
  }

  &__menu {
    @media(max-width: $screen-sm - 1) {
      padding: 30px 15px;
    }
  }

  /* ---------------------------------Content------------------------------------ */
  
  &__content-inner {
    float: right;
  }


  /* ---------------------------------Toggle------------------------------------ */

  &__toggle {
    @media(min-width: $screen-sm) {
      display: none;
    }
  }
}
