@mixin float() {

  @each $name, $breakpoint in $grid-breakpoints {

    @if($name == 'xs') {
      .float-none {
        float: none;
      }

      .float-left {
        float: left;
      }
      .float-right {
        float: right;
      }
    }

    @media (min-width: #{$breakpoint}) {
      .float-none-#{$name} {
        float: none;
      }

      .float-left-#{$name} {
        float: left;
      }
      .float-right-#{$name} {
        float: right;
      }
    }
  }
}
