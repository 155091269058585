@import "global";


























































































































































.google-map {
    width: 100%;
    height: 480px;
    margin: 0 auto;
    background: gray;
}
.is-active-on-map{
    background-color: #cda79d;
}
