.form-input {
  @include flex-row;
  align-items: center;

  &._textarea {
    align-items: normal;
  }

  /* ---------------------------------Title------------------------------------ */

  &__title {
    color: #3a3a3a;
    font-size: 14px;
    line-height: 26px;
    width: 86px;
    padding-right: 20px;

    @media(min-width: $screen-md) {
      width: 180px;
    }
  }

  &._mobile-full &__title {
    @media(max-width: $screen-md - 1) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  /* ---------------------------------Field------------------------------------ */

  &__field {
    color: #3a3a3a;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    flex-grow: 1;
    border: 2px solid #d9d6ca;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__field.error {
    border-color: #ff0000;
  }

  &__field:focus {
    border-color: #33712f;
  }

  &._textarea &__field {
    height: 90px;
    padding-top: 10px;
    resize: none;
  }

}