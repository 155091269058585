@mixin g-rectangle {
  width: 100%;
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 60%;
  }

  & > * {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }
}

@mixin g-square {
  width: 100%;
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  & > * {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }
}