/* ---------------------------------Hide------------------------------------ */
.hidden {
  display: none !important;
}

/* ---------------------------------PageBuffer------------------------------------ */

//.page-buffer {
//  &__end {
//    margin-top: 35px;
//    @media(min-width: $screen-md) {
//      margin-top: 50px;
//    }
//
//    @media(min-width: $screen-lg) {
//      margin-top: 80px;
//    }
//  }
//}

/* ---------------------------------Page wrapper------------------------------------ */

.page-wrapper {
  overflow: hidden;
}

/* ---------------------------------Page footer------------------------------------ */

.page-footer {
  overflow: hidden;
  //  padding-top: 20px;
}

@media(min-width: $screen-md) {
  //.page-footer {
  //  padding-top: 40px;
  //}
}

/* ---------------------------------Static Footer------------------------------------ */

@media (min-width: $screen-sm) {
  .page-wrapper {
    min-height: 100%;
  }
  //@include staticFooter(40px);
}
